body {
  margin: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.front-container {
  margin-top:0px;
  background-color: rgba(0, 0, 0, 0);
  color:white;
  width: 100%;
}

cover {
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

front-inner-container-base {
  padding-top: 0px;
  background: url("img/header.jpg") content-box center no-repeat;
  background-color: rgba(0, 0, 0, 0.0);
  width: 100%;
  text-align: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.link {
  cursor: pointer;
}


front-button-base {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  border: none;
  border-radius: 300px;
  padding: 15px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

@media (max-width: 800px) {
  .front-inner-container{
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    border: none;
    border-radius: 300px;
    padding: 15px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    margin-top: -200px;
  }

  .front-button{
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    border: none;
    border-radius: 300px;
    padding: 15px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  }
}

@media (min-width: 801px) {
  .front-inner-container{
    padding-top: 0px;
    background: url("img/header.jpg") content-box center no-repeat;
    background-color: rgba(0, 0, 0, 0.0);
    width: 100%;
    text-align: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    margin-top: -100px;
    height: 110vh;
  }

  .front-button{
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    border: none;
    border-radius: 300px;
    padding: 15px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  }
}





hr.front {
  border-color: orangered;
  border-width: 3px;
  max-width: 50px;
}

h1.front {
  padding-top: 200px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
}

h3.front {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

h3.front-black {
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}
p.front {
  font-family:  'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: large;
  color:rgba(255,255,255,0.8);
}
p.front-black {
  font-family:  'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: large;
  color:black
}

hr.light {
  border-color: white;
}

.text-default {
  color: rgb(50, 50, 50)
}

.bg-front-nav {
  background-color: rgba(0,0,0,0.3);
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: small;
  font-weight: 700;
}

.bg-front-dark {background-color: rgba(0, 0, 0, 0.8);} 

.bg-lunch {background-color: rgba(0, 0, 0, 0.3);} 


.bg-stripes {
  background: repeating-linear-gradient(
    -55deg,
    rgb(150, 150, 150),
    rgb(150, 150, 150) 10px,
    rgb(160, 160, 160) 10px,
    rgb(160, 160, 160) 20px
  );
}

.message-highlight {
  background: lightyellow;
}

.list-group > a.disabled {
  color: #999999;
}
.list-group > a.disabled:hover,
.list-group > a.disabled:focus {
  color: #999999;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}

.sticky-list {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 70px;
}